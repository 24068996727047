import React, { useContext, useEffect, useState } from "react";
import { Body } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { useNavigate } from "react-router-dom";
import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const Manege = () => {
    const { userInfos } = useContext(GlobalStateContext);
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        console.log("aqui", userInfos);
    }, [userInfos]);

    const items = [
        {
            label: 'Informações'
        },
        {
            label: 'Condições'
        },
        {
            label: 'Confirmação'
        }
    ];

    const handleNext = () => {
        if (activeIndex < items.length - 1) {
            setActiveIndex((prevIndex) => prevIndex + 1);
        } else {
            setShowDialog(true);
        }
    };

    const handleBack = () => {
        if (activeIndex > 0) {
            setActiveIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleFinish = () => {
        setShowDialog(false);
        navigate("/plans"); 
    };

    return (
        <Body>
            <h1 style={{ marginTop: "0px", padding: "30px"}}>Gerenciar Conta</h1>
            <p style={{ padding: "30px"}}>Etapas para cancelar assinatura.</p>
            <div className="card">
                <Steps model={items} activeIndex={activeIndex} className="steps"/>
                <div className="buttons mt-2">
                    <Button label="Voltar" onClick={handleBack} disabled={activeIndex === 0} className="mr-2 p-button" />
                    <Button label={activeIndex === items.length - 1 ? "Finalizar" : "Próxima"} onClick={handleNext} className="p-button" />
                </div>
            </div>

            <Dialog header="Confirmação" visible={showDialog} onHide={() => setShowDialog(false)} className="p-dialog">
                <p>Você completou todas as etapas. Deseja finalizar?</p>
                <Button label="Sim" onClick={handleFinish} className="mr-2 p-button" />
                <Button label="Não" onClick={() => setShowDialog(false)} className="p-button" />
            </Dialog>
        </Body>
    );
}

export default Manege;