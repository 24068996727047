import React, { useContext, useEffect, useState } from "react";
import { Body, ButtonImg } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { useNavigate } from "react-router-dom";
import seta from "../../../assests/imgs/setaLongaAcima.png"


const RoomCinema = () => {
    const { userInfos } = useContext(GlobalStateContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("aqui", userInfos);
    }, [userInfos]);

    return (
        <Body>
            <ButtonImg onClick={() => navigate('/')}> 
                <img src={seta}/>
            </ButtonImg>
            <h1 style={{ marginTop: "0px", padding: "30px"}}>Sala de Cinema</h1>

        </Body>
    );
}

export default RoomCinema;