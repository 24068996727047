import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import styled from 'styled-components';

const VimeoPlayer = ({ url }) => {
  return (
      <ReactPlayer
        url={'https://vimeo.com/896300909?share=copy'}
        width='100%'
        height='100vh'
        style={{position: "absolute", top: 0}}
        controls
        // Adicione outras propriedades conforme necessário
      />
  );
};

export default VimeoPlayer;

const PlayerWrapper = styled.div`
    display: flex;
    flex-direction: column;

`;