import styled from "styled-components";




export const Body = styled.div`
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    background-size: cover;
    background-position: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

export const LogoB = styled.img`
width: 50px;
height: 96px;
`

export const CardLogin = styled.div`
    height: 594px;
    width: 510px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
`

export const TittleCard = styled.text`
font-style: normal;
font-weight: 300;
font-size: 30px;
line-height: 29px;
letter-spacing: 0.2em;
margin-right: 60px;
margin-top: 40px;
margin-bottom: 30px;

`
export const InputRegister = styled.input`
    height: 50px;
    width: 440px;
    background: #D9D9D9;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    padding-left: 10px;

    @font-face {
    font-family: 'Modern Sans', sans-serif;
    src: url(font) format("ttf");
}

    ::placeholder{
    font-weight: 100;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.2em;
    }


`

export const CheckBoxText = styled.text`
    font-style: normal;
    font-weight: 300;
    line-height: 29px;
    font-size: 15px;
    letter-spacing: 0.2em;
    text-decoration: underline;
    cursor: pointer;

`

export const ButtonRegister = styled.button`
    width: 470px;
    height: 55px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.35%, #FC9B54 100%);
    border: none;
    border-radius: 5px;
    margin-top: 30px;
    font-weight: 100;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.2em;
    cursor: pointer;

    :hover{
        color: white;
    }

`

