import React, { useContext, useState } from "react";
import {BodyImg, TittlePage, SubTittle, StyledCarousel } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import MenuHome from "../../../Components/Menu";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'primereact/carousel';


const Exclusives = () => {
    const { userInfos } = useContext(GlobalStateContext);

    const navigate = useNavigate();

    const mockProducts = [
        {
            id: 1,
            name: "Bem-Vinda de Volta",
            videoUrl: "https://www.youtube.com/embed/ejnUwcl03kk?si=9X9pZ2yFMHUAQA3M" 
          },
          {
            id: 2,
            name: "Desenredo",
            videoUrl: "https://www.youtube.com/embed/u71LIPD_w7E?si=Q86YtkrDzg_7sx15"
          },
          {
            id: 3,
            name: "Quentinho ",
            videoUrl: "https://www.youtube.com/embed/SjGTWVCF1Kg?si=NEW-tgZIMlSM_66A"
          },
        {
            id: 4,
            name: "O Primeiro Encontro (The First Date)",
            videoUrl: "https://www.youtube.com/embed/dbyVSZl2b3o?si=E-Ae3my4DRdwTDYF"
          },
          {
            id: 5,
            name: "ESTRANHAS",
            videoUrl: "https://www.youtube.com/embed/b4vbmKe1HVM?si=BB-g9khrLPARp6CU"
          },
          {
            id: 6,
            name: "Felícia",
            videoUrl: "https://www.youtube.com/embed/id8HywXx6g0?si=sGrUCmO14Qt1Fzxw" 
          },
  
      ];

      const mockProducts2 = [
        {
          id: 1,
          name: `Stupid Wife - 1ª Temporada - 1x01 "Acordar"`,
          videoUrl: "https://www.youtube.com/embed/kFjMAw5sw_o?si=Z3zQS6VVDXnbm_eF" 
        },
        {
          id: 2,
          name: `Stupid Wife - 1ª Temporada - 1x02 "Lembranças"`,
          videoUrl: "https://www.youtube.com/embed/vq8-KiSRhNA?si=S53Re_SWtbs5f6Z8" 
        },
        {
          id: 3,
          name: `Stupid Wife - 1ª Temporada - 1x03 "Recomeçar"`,
          videoUrl: "https://www.youtube.com/embed/OH7nhDJ9y5k?si=Jz-4N21TpCUXKok4"
        },
        {
            id: 4,
            name: `Stupid Wife - 1ª Temporada - 1x04 "Cicatrizes"`,
            videoUrl: "https://www.youtube.com/embed/DmcjlG8EuvA?si=7HqSYpIPrwaq4mws"
          },
          {
            id: 5,
            name: `Stupid Wife - 1ª Temporada - 1x05 "Refúgio"`,
            videoUrl: "https://www.youtube.com/embed/3nxJqGznrY8?si=uHTRxhex4BrRJqAY"
          },
          {
            id: 6,
            name: `Stupid Wife - 1ª Temporada - 1x06 "Desejo"`,
            videoUrl: "https://www.youtube.com/embed/leIX-CKEW-0?si=foQudvWoxN_0oHGC"
          },
  
      ];

      const mockProducts3 = [
        {
          id: 1,
          name: "Lesbocine - Especial Mês Lésbico",
          videoUrl: "https://www.youtube.com/embed/rjkdOGKCOtc?si=pJwWumquCv7MWnvz"
        },
        {
          id: 2,
          name: "Lesbocine - CULTURA POP: GAP THE SERIES & MUNDINHO GL",
          videoUrl: "https://www.youtube.com/embed/gblhbKqewxI?si=ezdjFLcyHNfziMEM"
        },
        {
          id: 3,
          name: "Lesbocine - FOFOCA DO REBU",
          videoUrl: "https://www.youtube.com/embed/zyw3qmtXOgc?si=oubTu3C9J74RokQU"
        },
        {
            id: 4,
            name: "Lesbocine - REAGINDO A GAP THE SERIES & MUNDINHO GL",
            videoUrl: "https://www.youtube.com/embed/6JrMN5U7c6k?si=OnyWVm5D87ZLVBa4"
          },
          {
            id: 5,
            name: "Lesbocine - MEIA NOITE EU TE CONTO COM ANNA BAGUNCEIRA",
            videoUrl: "https://www.youtube.com/embed/IVBIE40RSfA?si=gathpG1HhRsXUusJ" 
          },
          {
            id: 6,
            name: "Lesbocine - CULTURA POP: CASAIS SÁFICOS EM NOVELA",
            videoUrl: "https://www.youtube.com/embed/e-nY4G8W2Pg?si=uvJfnCn7cjFE0Nsr"
          },
  
      ];

      const mockProducts4 = [
        {
          id: 1,
          name: "SURTEI COM OS VÍDEOS DA PROTAGONISTA DE BLANK THE SERIES! - Faye Peraya",
          videoUrl: "https://www.youtube.com/embed/FtvOq-HRp_8?si=seFqzAn_92uVsi9W"
        },
        {
          id: 2,
          name: "A PRIMEIRA VEZ DELAS! - Blank The Series episódio 6",
          videoUrl: "https://www.youtube.com/embed/pL4KZcTQyuk?si=jZCZWY4VwSnz3WHL"
        },
        {
          id: 3,
          name: "O PRIMEIRO BEIJO DA MUCILON? - Blank The Series episódio 5",
          videoUrl: "https://www.youtube.com/embed/hdXMxF9PBkU?si=LbfOmUPG1MVRVpwq"
        },
        {
            id: 4,
            name: "A SUN VAI DESCOBRIR TUDO? (MILKLOVE) 23.5 องศาที่โลกเอียง (Episódio 4 e 5)",
            videoUrl: "https://www.youtube.com/embed/cC2pdXXtXF0?si=BXgOaqnudyzfL3yF"
          },
          {
            id: 5,
            name: "CRENTES ENSINANDO CRIANÇAS A TEREM PRECONCEITO",
            videoUrl: "https://www.youtube.com/embed/eZMnhzEqHRs?si=Uv_xw6Q-ffspHwwQ" 
          },
          {
            id: 6,
            name: "EU REAGI A BOMBA: 365 dias",
            videoUrl: "https://www.youtube.com/embed/OJZMYc-lFBc?si=7XuGwJs5KkzHnDQ4"
          },
  
      ];
      

    const [products, setProducts] = useState(mockProducts);
    const [products2, setProducts2] = useState(mockProducts2);
    const [products3, setProducts3] = useState(mockProducts3);
    const [products4, setProducts4] = useState(mockProducts4);
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    
    
    const productTemplate = (product) => {
        if (product.videoUrl) {
            return (
                <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                    <div className="mb-3">
                        <iframe style={{ width: "96%", height: "214px", borderRadius: "0px 30px 0px 0px" }} src={product.videoUrl} title={product.name} allowFullScreen></iframe>
                    </div>

                </div>
            );
        } else if (product.image) {
            return (
                <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                    <div className="mb-3">
                        <img style={{ width: "96%", height:"214px", borderRadius: "0px 30px 0px 0px"}} src={`${product.image}`} alt={product.name} className="w-6 shadow-2" />
                    </div>

                </div>
            );
        }
    };

    return (
        <>
            <BodyImg>
                <MenuHome />
                <div style={{ display: "flex",  flexDirection: "column", marginLeft: "100px", marginTop: "60px"}}>
                    <TittlePage>
                    CURTAS
                    </TittlePage>

                </div>
                <StyledCarousel>
                <div className="card">
                    <Carousel value={products} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                     itemTemplate={productTemplate} />
                </div>
                </StyledCarousel>
                <div style={{ display: "flex",  flexDirection: "column", marginLeft: "100px", marginTop: "60px"}}>
                    <TittlePage>
                    WEBSÉRIES
                    </TittlePage>

                </div>
                <StyledCarousel>
                <div className="card">
                    <Carousel value={products2} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                     itemTemplate={productTemplate} />
                </div>
                </StyledCarousel>
                <div style={{ display: "flex",  flexDirection: "column", marginLeft: "100px", marginTop: "60px"}}>
                    <TittlePage>
                    PODCASTS
                    </TittlePage>

                </div>
                <StyledCarousel>
                <div className="card">
                    <Carousel value={products3} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                     itemTemplate={productTemplate} />
                </div>
                </StyledCarousel>

                <div style={{ display: "flex",  flexDirection: "column", marginLeft: "100px", marginTop: "60px"}}>
                    <TittlePage>
                    REACTS
                    </TittlePage>

                </div>
                <StyledCarousel>
                <div className="card">
                    <Carousel value={products4} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                     itemTemplate={productTemplate} />
                </div>
                </StyledCarousel>

            </BodyImg>
        </>
    )
}

export default Exclusives;