import React, { useContext, useState, useEffect } from "react";
import { Body, BodyImg, Box, BoxNoHover, ButtonDisplay, DivideBody, MidImg, MidPosition, TextBody, TittlePage, TittlePageName } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import MenuHome from "../../../Components/Menu";
import texthomepage from "../../../assests/imgs/texthomepage.png"
import fisrImg from "../../../assests/imgs/Bottoms.png"
import secImg from "../../../assests/imgs/Gloria-Pires.png"
import terstImg from "../../../assests/imgs/imagine-you-and-me.png"
import ButtonGradient from "../../../Components/Buttons/ButtonGradient";
import ButtonBranco from "../../../Components/Buttons/ButtonBranco";
import { useNavigate } from "react-router-dom";
import fundohomepage from "../../../assests/imgs/fundo-homepage.png";
import fundohomepagevideo from "../../../assests/videos/happiestSeasonTrailerPTBR.mp4";
import { Carousel } from "primereact/carousel";
import exemploimg from "../../../assests/imgs/Gloria-Pires.png"
import floresRaras from "../../../assests/imgs/Gloria-Pires.png"
import imagineYou from "../../../assests/imgs/Filme - Imagine eu e você.jpg"
import bottoms from "../../../assests/imgs/Filme - Bottoms.jpg"
import loveLies from "../../../assests/imgs/Love-Liers-Bleeding.jpg"
import theWorld from "../../../assests/imgs/Filme - The world to come.jpg"
import rafiki from "../../../assests/imgs/Filme - Rafiki.png"
import criada from "../../../assests/imgs/Filme - A criada.jpg"
import glee from "../../../assests/imgs/Glee.png"
import orange from "../../../assests/imgs/Série - Orange is the nwe black.jpg"
import theL from "../../../assests/imgs/The-L-World.jpg"
import wynonna from "../../../assests/imgs/Série - Wynonna Earp.jpg"
import imNot from "../../../assests/imgs/Série - I'm not okay with this.png"
import everything from "../../../assests/imgs/Série - Everything Sucks.png"


const HomePage = () => {
  const { userInfos } = useContext(GlobalStateContext);

  const navigate = useNavigate();



  const [isVideoEnded, setIsVideoEnded] = useState(false);

  // Função para tratar o evento de término do vídeo
  const handleVideoEnd = () => {
    setIsVideoEnded(true);
  };

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  const mockProducts2 = [
    {
      id: 1,
      name: "Flores Raras",
      image: floresRaras
    },
    {
      id: 2,
      name: "Imagine Me and You",
      image: imagineYou
    },
    {
      id: 3,
      name: "Bottoms",
      image: bottoms
    },
    {
      id: 4,
      name: "Love Lies Bleeding",
      image: loveLies
    },
    {
      id: 5,
      name: "The World To Come",
      image: theWorld
    },
    {
      id: 6,
      name: "Rafiki",
      image: rafiki
    },
    {
      id: 7,
      name: "A Criada",
      image: criada
    }

  ];

  const mockProducts3 = [
    {
      id: 1,
      name: "Everything Sucks",
      image: everything
    },
    {
      id: 2,
      name: "Glee",
      image: glee
    },
    {
      id: 3,
      name: "Orange Is The New Black",
      image: orange
    },
    {
      id: 1,
      name: "The L Word",
      image: theL
    },
    {
      id: 2,
      name: "Wynonna",
      image: wynonna
    },
    {
      id: 3,
      name: "I'm Not Okay With This",
      image: imNot
    },

  ];


  const mockProducts = [
    {
      id: 1,
      name: "Orange Is The New Black",
      image: orange
    },
    {
      id: 2,
      name: "Imagine Me and You",
      image: imagineYou
    },
    {
      id: 3,
      name: "Bottoms",
      image: bottoms
    },
    {
      id: 1,
      name: "Love Lies Bleeding",
      image: loveLies
    },
    {
      id: 2,
      name: "Série E",

      image: exemploimg
    },
    {
      id: 3,
      name: "Série F",
      image: exemploimg
    },

  ];

  const [products, setProducts] = useState(mockProducts);
  const [products2, setProducts2] = useState(mockProducts2);
  const [products3, setProducts3] = useState(mockProducts3);

  const productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          <img style={{ width: "96%", height: "214px", borderRadius: "20px" }} src={`${product.image}`} alt={product.name} className="w-6 shadow-2" />
          <div style={{ position: "relative", left: '80%', bottom: 70, height: 50, width: 50, backgroundColor: "red" }} >

          </div>
        </div>
        {/* <div>
                    <h4 style={{fontWeight: 400}} className="mb-1">{product.name}</h4>
                </div> */}
      </div>
    );
  };

  useEffect(() => {
    // Este código irá verificar se o vídeo terminou e irá trocar para a imagem estática.
    const video = document.getElementById('background-video');
    if (video) {
      video.addEventListener('ended', handleVideoEnd);
    }

    // Cleanup function para remover o event listener
    return () => {
      if (video) {
        video.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, []);

  return (
    <>
      <BodyImg>
        <MenuHome />
        {isVideoEnded ? (
          // Exibe a imagem estática após o vídeo ter terminado
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <img style={{ height: '100%', width: '100%' }} src={fundohomepage} alt="Fundo Estático" />
            <div style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "30%",
              backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1.5))"
            }}></div>
          </div>
        ) : (
          // Renderiza o vídeo antes de terminar
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <video
              id="background-video"
              autoPlay
              muted
              onEnded={handleVideoEnd}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}>
              <source src={fundohomepagevideo} type="video/mp4" />
            </video>
            <div style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "30%",
              backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1.6))"
            }}></div>
          </div>
        )}
        <div style={{ position: "absolute", bottom: "0", marginBottom: 50 }}>
          <MidPosition>
            <MidImg src={texthomepage} />
          </MidPosition>
          <ButtonDisplay>
            <ButtonGradient onClick={() => navigate('/movieScreen')} />
            <ButtonBranco onClick={() => navigate('/infos')} />
          </ButtonDisplay>
        </div>

      </BodyImg>
      <Body>
        <DivideBody style={{ gap: "40px" }}>
          <BoxNoHover style={{ width: "45%", flexDirection: "column", justifyContent: 'center', marginTop: '3%' }}>
            <TextBody>TUDO O QUE VOCÊ QUER ASSISTIR, EM UM SÓ LUGAR!</TextBody>
          </BoxNoHover>

        </DivideBody>
        <DivideBody style={{ marginTop: 50 }}>


          <div className="card">
            <TextBody style={{ fontSize: 25, color: 'white', marginLeft: '50px' }}>Recomendados para você</TextBody>
            <Carousel value={products} numVisible={5} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular data-pr-autohide
              itemTemplate={productTemplate} />
          </div>
        </DivideBody>
        <DivideBody style={{ marginTop: 20 }}>


          <div className="card">
            <TextBody style={{ fontSize: 25, color: 'white', marginLeft: '50px' }}>Filmes</TextBody>
            <Carousel value={products2} numVisible={5} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular data-pr-autohide
              itemTemplate={productTemplate} />
          </div>
        </DivideBody>
        <DivideBody style={{ marginTop: 20, marginBottom: "5%" }}>
          <div className="card">
            <TextBody style={{ fontSize: 25, color: 'white', marginLeft: '50px' }}>Séries</TextBody>
            <Carousel value={products3} numVisible={5} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular data-pr-autohide
              itemTemplate={productTemplate} />
          </div>
        </DivideBody>
      </Body>
    </>
  )
}

export default HomePage;