
import {ButtonG} from "./styled";
import React, { useContext } from "react";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import iconplay from "../../../assests/imgs/iconeplay.png"

const ButtonGradient = ({onClick, text}) => {
    const { userInfos } = useContext(GlobalStateContext);


    return (
        <ButtonG onClick={() => onClick()}>
            <img src={iconplay}/>
            {text}
        </ButtonG>
    )
}

export default ButtonGradient;