import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Login from "../Pages/Unlogged/Login";
import ReactDOM from "react-dom/client";
import App from "../App";
import Register from "../Pages/Unlogged/Register";
import Home from "../Pages/Logged/Home";
import HomePage from "../Pages/Logged/HomePage";
import MovieScreen from "../Pages/Logged/MovieScreen";
import Start from "../Pages/Unlogged/Start";
import Plans from "../Pages/Unlogged/Plans";
import Series from "../Pages/Logged/Series";
import Films from "../Pages/Logged/Film";
import Exclusives from "../Pages/Logged/Exclusives";
import Info from "../Pages/Logged/Info";
import Fidelity from "../Pages/Logged/Fidelity"
import Support from "../Pages/Logged/Support";
import Manege from "../Pages/Logged/Manege";
import PayamentMethod from "../Pages/Logged/PayamentMethod";
import RoomCinema from "../Pages/Logged/RoomCinema";
import ProfileManege from "../Pages/Logged/ProfileManege";
import RegisterPag from "../Pages/Unlogged/RegisterPag";
import LoginAdmin from "../Pages/Admin/LoginAdmin";
import UpFilm from "../Pages/Admin/UpFilm";

const Routers = () => {


    return(
        <BrowserRouter>
        <Routes>
        <Route path="/" element={<HomePage />}></Route>
          {/* <Route path="/" element={<Start/>}></Route> */}
          <Route path="/plans" element={<Plans/>}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/registerpag" element={<RegisterPag />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/series" element={<Series />}></Route>
          <Route path="/films" element={<Films />}></Route>
          <Route path="/exclusives" element={<Exclusives />}></Route>
          <Route path="/infos" element={<Info />}></Route>
          <Route path="/fidelity" element={<Fidelity />}></Route>
          <Route path="/support" element={<Support />}></Route>
          <Route path="/manege" element={<Manege />}></Route>
          <Route path="/payamentMethod" element={<PayamentMethod/>}></Route>
          <Route path="/roomCinema" element={<RoomCinema/>}></Route>
          <Route path="/profileManege" element={<ProfileManege/>}></Route>
          <Route path="/movieScreen" element={<MovieScreen />}></Route>


            {/* Paginas de administração */}
          <Route path="/loginadmin" element={<LoginAdmin />}></Route>
          <Route path="/upfilm" element={<UpFilm />}></Route>
        </Routes>
      </BrowserRouter>
    )
}

export default Routers;