import styled from "styled-components";
import { Accordion, AccordionTab } from 'primereact/accordion';

export const Body = styled.div`
    width: 100%;
    height: 100vh;
    justify-content: center;
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    background-size: cover;
    background-position: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
`;

export const Card = styled.div`
    width: 60%;
`;


