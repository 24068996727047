import styled from "styled-components";


export const Body = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    background-size: cover;
    background-position: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    z-index: 1;

`

export const LogoImg = styled.img`
    width: 289px;
`


export const Card = styled.div`

    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
    margin-left: 150px;
`

export const Slogan = styled.text`
display: flex;
width: 577px;
height: 68px;
font-size: 35px;
font-weight: 500;
line-height: 34px;
letter-spacing: 0em;
text-align: center;

margin-bottom: 70px;


color: #FFFFFF;

`



export const ButtonLogin = styled.button`
    width: 167px;
    height: 33px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.35%, #FC9B54 100%);
    border: none;
    border-radius: 5px;
    margin-right: 140px;
    font-weight: 100;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.2em;
    cursor: pointer;

    color: #FFFFFF;

`

export const ButtonRegister = styled.button`
    width: 476px;
    height: 78px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.35%, #FC9B54 100%);
    border: none;
    border-radius: 5px;
    margin-top: 30px;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-align: center;
    cursor: pointer;

    color: #FFFFFF;

`

export const ButtonFree = styled.button`
    width: 542px;
    height: 60px;
    background: linear-gradient(180deg, #961F01 0%, rgba(150, 31, 1, 0.569931) 43.01%, rgba(150, 31, 1, 0.289931) 71.01%, rgba(150, 31, 1, 0.43) 85.01%, rgba(150, 31, 1, 0) 100%);
    border: none;
    border-radius: 5px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2em;
    text-align: center;
    cursor: pointer;

    color: #FFFFFF;


`

export const TextPag = styled.text`
    width: 406px;
    font-style: normal;
    font-weight: 300;
    line-height: 29px;
    font-size: 15px;
    letter-spacing: 0.2em;
    margin-left: 20px;
    margin-bottom: 50px;
    text-align: center;

    color: #FFFFFF;

`

