import React, { useState, useEffect } from "react";

import iconTerm from "../../assests/imgs/icon-term.png";
import pontTerm from "../../assests/imgs/local-term.png";
import barTerm from "../../assests/imgs/barra-term.png";

const Therm = () => {
    const [likes, setLikes] = useState(0);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const maxLikes = 100;
    const barWidth = 400;

    useEffect(() => {
        const savedLikes = localStorage.getItem('likes');
        const hasLiked = localStorage.getItem('liked');
        const hasDisliked = localStorage.getItem('disliked');
        if (savedLikes) {
            setLikes(parseInt(savedLikes));
            setLiked(hasLiked === 'true');
            setDisliked(hasDisliked === 'true');
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('likes', likes);
        localStorage.setItem('liked', liked);
        localStorage.setItem('disliked', disliked);
    }, [likes, liked, disliked]);

    const markerPosition = Math.min((likes / maxLikes) * barWidth, barWidth - 20);

    const addLike = () => {
        if (!liked) {
            setLikes(liked ? likes - 1 : likes + 1);
            setLiked(true);
            setDisliked(false);
        }
    };

    const removeLike = () => {
        if (!disliked) {
            setLikes(disliked ? likes + 1 : likes - 1);
            setLiked(false);
            setDisliked(true);
        }
    };

    return (
        <div style={{ position: "absolute", right: "0", top: "200px" }}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p style={{ fontSize: "25px", fontWeight: "500", lineHeight: "24.3px", textAlign: "left", color: "#FFFFFF" }}>Meta de Renovação</p>
                <img src={iconTerm} style={{width: "28px", height: "28px"}}/>
            </div>
            <div>
                <img src={barTerm} alt="Barra de Progresso" />
                <img src={pontTerm} style={{position: "absolute", left: `${markerPosition}px`}} alt="Marcador" />
            </div>
            <div style={{marginTop: 6}}>
                <button onClick={addLike} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 48 48" fill="none" stroke={liked ? "red" : "currentColor"}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M15 8C8.925 8 4 12.925 4 19c0 11 13 21 20 23.326C31 40 44 30 44 19c0-6.075-4.925-11-11-11c-3.72 0-7.01 1.847-9 4.674A10.987 10.987 0 0 0 15 8"/>
                    </svg>
                </button>
                <button onClick={removeLike} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 48 48" fill="none" stroke={disliked ? "#007BFF" : "currentColor"}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="m24 31l-3-5l7-6l-9-5l1-5.8C18.5 8.432 16.8 8 15 8C8.925 8 4 12.925 4 19c0 11 13 21 20 23c7-2 20-12 20-23c0-6.075-4.925-11-11-11c-1.8 0-3.5.433-5 1.2"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Therm;
