
import {ButtonB} from "./styled";
import React, { useContext } from "react";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import iconinfo from "../../../assests/imgs/iconeinfo.png"

const ButtonBranco = ({onClick, text}) => {
    const { userInfos } = useContext(GlobalStateContext);
    return (
        <ButtonB onClick={() => onClick()}>
            <img src={iconinfo} />
            Mais informações
        </ButtonB>
    )
}

export default ButtonBranco;