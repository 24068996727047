import React, { useContext, useRef, useState } from "react";
import { Body, ButtonLogin, CardLogin, CheckBox, CheckBoxText, ForgetButton, InputLogin, TittleCard } from "./styled";
import { useNavigate } from 'react-router-dom';
import api from "../../../services/api";
import { SkewLoader } from "react-spinners";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { Checkbox } from "primereact/checkbox";
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import styled from "styled-components";
import { InputText } from 'primereact/inputtext';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const { setUserInfos, setLogged } = useContext(GlobalStateContext);

    const CustomCheckbox = styled(Checkbox)`
        .p-checkbox-box {
            background: linear-gradient(to right, #A40464, #f45b69);
            border: none;
        }

        .p-checkbox-box.p-highlight {
            background: linear-gradient(to right, #A40464, #f45b69);
        }

        .p-checkbox-box .p-checkbox-icon {
            color: white;
        }
    `;

    const handleLogin = () => {
        setLoading(true);
        api.post('/login', { email, password })
            .then((res) => {
                localStorage.setItem("@token", res.data.token.token);
                setUserInfos(res.data);
                navigate('/home');
                setLogged(true);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                setLogged(false);
            });
    };

    const handleKeyPressEmail = (event) => {
        if (event.key === 'Enter') {
            document.getElementById("passwordInput").focus(); // Move o foco para o campo de senha
        }
    };

    const handleKeyPressPassword = (event) => {
        if (event.key === 'Enter') {
            handleLogin(); // Executa a função de login
        }
    };

    return (
        <Body>
            {loading &&
                <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", zIndex: 10 }}>
                    <SkewLoader color="#A40464" loading={true} size={50} />
                </div>
            }
            <CardLogin>
                <div style={{ width: "100%", marginTop: 30, marginLeft: 70 }}>
                    <TittleCard>Entrar</TittleCard>
                </div>
                <InputText onKeyDown={handleKeyPressEmail} value={email} onChange={e => setEmail(e.target.value)} style={{ marginTop: 30, width: '90%' }} placeholder="Digite aqui o seu e-mail" />
                <div style={{ position: 'relative', width: '90%', marginTop: 20 }}>
                    <InputText
                        id="passwordInput"
                        type={passwordVisible ? "text" : "password"}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={handleKeyPressPassword}
                        placeholder="Digite aqui a sua senha"
                        style={{ width: '100%' }}
                    />
                    <span style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => setPasswordVisible(!passwordVisible)}>
                        {passwordVisible ? <EyeOffIcon style={{ width: 20, height: 20 }} /> : <EyeIcon style={{ width: 20, height: 20 }} />}
                    </span>
                </div>
                <ButtonLogin id="loginInput" onClick={handleLogin}>Entrar</ButtonLogin>
                <ForgetButton style={{ marginTop: 10 }}>Esqueci minha senha</ForgetButton>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 20, width: "100%", marginLeft: 70 }}>
                    <div className="card flex justify-content-center">
                        <CustomCheckbox onChange={e => setChecked(e.checked)} checked={checked} />
                    </div>
                    <CheckBoxText style={{ marginLeft: 10 }}>Lembrar meu acesso</CheckBoxText>
                </div>
                <div style={{ width: "100%", marginLeft: 40, marginTop: 10 }}>
                    <CheckBoxText style={{ fontSize: 13, marginTop: 30 }}>Ainda não faz parte da nossa comunidade?</CheckBoxText>
                    <ForgetButton onClick={() => navigate('/plans')} style={{ fontSize: 13 }}>Conheça nossos planos</ForgetButton>
                </div>
            </CardLogin>
        </Body>
    );
}

export default Login;
