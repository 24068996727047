import { animated, useSpring } from "@react-spring/web";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import logocomnome from "../../assests/imgs/logocomnome.png";
import profile from "../../assests/imgs/profile.png";
import logosemnome from "../../assests/imgs/simbolosemnome.png";
import sino from "../../assests/imgs/Sino.png";
import GlobalStateContext from "../../GlobalState/GlobalStateContext";
import ButtonGradientEdit from "../Buttons/ButtonGradientEdit";
import { StyledMenu } from "./styled";

const Header = styled(animated.div)`
  width: 100%;
  position: fixed;
  top: 0;
  transition: top 0.3s;
  z-index: 10;
`;

const ImageContainer = styled.div`
  width: 200px;
  height: 100px;
  margin-left: 40px;
  cursor: pointer;
  position: relative;
`;

const Image = styled.div`
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: display 0.3s ease;

  &:first-child {
    background-image: url(${logosemnome});
    display: block;
  }

  &:last-child {
    background-image: url(${logocomnome});
    display: none;
  }

  ${ImageContainer}:hover & {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }
`;

const ImagesemNome = styled.div`
  height: 100px;
  width: 55px;
  background-image: url(${logosemnome});
  background-size: cover;
  transition: background-image 0.3s ease;
  margin-left: 100px;

  &:hover {
    background-image: url(${logocomnome});
    width: 200px;
    height: 100px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  color: white;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 19px;
  letter-spacing: 0.2em;
  cursor: pointer;
  margin: 0 10px;

  :hover {
    opacity: 0.5;
  }

  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;

const Body = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImgPorfile = styled.img`
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
`;
const DetailsPanel = styled.div`
  background: white;
  color: black;
  padding: 20px;
  position: absolute;
  width: 300px;
  right: 0;
  top: 105%;
  display: ${(props) => (props.isVisible ? "block" : "none")};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;

  p {
    margin: 10px 0;
  }
`;

const MenuHome = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);

  const { logged } = useContext(GlobalStateContext);
  // const logged = true
  const navigate = useNavigate();

  const fade = useSpring({
    from: { opacity: 0, x: -100 },
    to: { opacity: 1, x: 0 },
    config: { duration: 1000 },
  });

  const menuRef = useRef(null);

  // Definindo o array de itens para o menu
  const [items] = useState([
    {
      label: "Gerenciamento de Conta",
      icon: "pi pi-user",
      items: [
        {
          label: "Alternar Perfil",
          icon: "pi pi-user-edit",
          command: () => navigate("/home"),
        },
        {
          label: "Gerenciar Contas",
          icon: "pi pi-cog",
          command: () => navigate("/manege"),
        },
        {
          label: "Métodos de Pagamento",
          icon: "pi pi-credit-card",
          command: () => navigate("/payamentMethod"),
        },
      ],
    },
    {
      label: "Ferramentas Exclusivas",
      icon: "pi pi-tool",
      items: [
        {
          label: "Recompensas",
          icon: "pi pi-star",
          command: () => navigate("/fidelity"),
        },
        {
          label: "Criar sala de Cinema",
          icon: "pi pi-video",
          command: () => navigate("/roomCinema"),
        },
        {
          label: "Suporte ao Cliente",
          icon: "pi pi-comments",
          command: () => navigate("/support"),
        },
      ],
    },
  ]);

  const handleNodeSelect = (path) => {
    console.log(`Navegando para ${path}`);
    navigate(path); // Navega para o caminho especificado
  };

  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      // Detecta se o usuário fez scroll suficiente para "sair" do topo.
      const isTop = window.pageYOffset < 50;
      setHasScrolled(!isTop); // Atualiza o estado baseado se está ou não no topo.
      if (isTop) {
        setIsVisible(true); // Se está no topo, garante que o cabeçalho esteja visível.
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const onMouseMove = (event) => {
      // Aplica a lógica do mouse apenas se o usuário tiver feito scroll para baixo.
      if (hasScrolled) {
        const shouldBeVisible = event.clientY <= 100;
        setIsVisible(shouldBeVisible);
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    return () => document.removeEventListener("mousemove", onMouseMove);
  }, [hasScrolled]); // Dependência para reagir às mudanças do estado hasScrolled.

  const headerStyle = useSpring({
    to: {
      transform: isVisible ? `translateY(0)` : `translateY(-100%)`,
      opacity: isVisible ? 1 : 0, // Adicionado para efeito de fade junto com o slide
      config: { mass: 1, tension: 210, friction: 20 },
    },
  });

  const handleClick = (e) => {
    setShowDetails(!showDetails); // Chama a função toggleDetails
    if (menuRef.current) {
      menuRef.current.toggle(e); // Chama o método toggle do menu
    } else {
      console.error("Menu reference is null");
    }
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Header style={headerStyle}>
      <Body>
        <ImageContainer onClick={handleLogoClick}>
          <Image style={{ height: 100, width: 55 }} /> {/* Imagem sem nome */}
          <Image /> {/* Imagem com nome */}
        </ImageContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <animated.div style={fade}>
            <TextWrapper>
              <Text onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                Início
              </Text>
              <Text
                onClick={() => navigate("/series")}
                style={{ cursor: "pointer" }}
              >
                Séries
              </Text>
              <Text
                onClick={() => navigate("/films")}
                style={{ cursor: "pointer" }}
              >
                Filmes
              </Text>
              <Text
                onClick={() => navigate("/exclusives")}
                style={{ cursor: "pointer" }}
              >
                Exclusivos
              </Text>
              {logged ? (
                <Text style={{ cursor: "pointer", width: 230 }}>
                  Ela faz a lista dela
                </Text>
              ) : null}
            </TextWrapper>

            {showDetails && (
              <div
                className="card"
                style={{
                  position: "absolute",
                  right: 0,
                  backgroundColor: "black",
                }}
              ></div>
            )}
          </animated.div>
        </div>
        <div
          style={{
            marginRight: 70,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="p-input-icon-left">
            <i style={{ marginLeft: 3 }} className="pi pi-search" />
            <InputText
              style={{
                backgroundColor: "transparent",
                color: "white",
                width: 150,
                paddingLeft: 20,
              }}
              placeholder="Pesquisar"
            />
          </span>

          {logged ? (
            <>
              <img style={{ height: 35, marginLeft: 10 }} src={sino} />
              <ImgPorfile
                style={{ height: 45, marginLeft: 10 , cursor: "pointer"}}
                src={profile}
                onClick={handleClick}
              />
              <StyledMenu model={items} popup ref={menuRef} />
            </>
            
          ) : (
            <ButtonGradientEdit
              onClick={() => navigate("/login")}
              text={"Login"}
              width={100}
              height={20}
            />
          )}
        </div>
      </Body>
    </Header>
  );
};

export default MenuHome;
