import styled from "styled-components";

export const Body = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
  background-size: cover;
  background-position: center;
  background-color: black;
  display: flex;
  flex-direction: column;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4.5rem 15rem;

  img {
    width: 70px;
    height: 120px;
    margin-right: 12rem;
  }
`;

export const TittlePage = styled.text`
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 50px;
  color: white;
  letter-spacing: 0.28em;
`;

export const TittlePageName = styled.text`
  font-style: normal;
  font-weight: 100;
  font-size: 30px;
  line-height: 29px;

  color: white;
  margin-top: 20px;
`;


export const MidImg = styled.img`
  height: 200px;
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  border-radius: 20px;
  background-color: white;
  cursor: pointer;

  :hover{
    background-color: #e0e0e0;
  }
`;

export const ButtonImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MidPosition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button{
    margin-top: 35px;
  }
`;
