import React, { useState } from "react";
import GlobalStateContext from "./GlobalStateContext";

const GlobalState = (props) => {
    const [userInfos, setUserInfos] = useState("")
    const [logged, setLogged] = useState(false)

    const datas = {
        setUserInfos,
        userInfos,
        logged,
        setLogged
    }



    return(
        <GlobalStateContext.Provider value={datas}>
            {props.children}
        </GlobalStateContext.Provider>
    )
}

export default GlobalState;