import React, { useContext, useState, useEffect } from "react";
import { Body, BodyImg, Box, BoxNoHover, ButtonDisplay, DivideBody, MidImg, MidPosition, TextBody, TittlePage, TittlePageName } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import MenuHome from "../../../Components/Menu";
import texthomepage from "../../../assests/imgs/texthomepage.png"
import fisrImg from "../../../assests/imgs/Bottoms.png"
import secImg from "../../../assests/imgs/Gloria-Pires.png"
import terstImg from "../../../assests/imgs/imagine-you-and-me.png"
import ButtonGradient from "../../../Components/Buttons/ButtonGradient";
import ButtonBranco from "../../../Components/Buttons/ButtonBranco";
import { useNavigate } from "react-router-dom";
import fundoinfo from "../../../assests/imgs/fundoinfo.png";
import fundohomepagevideo from "../../../assests/videos/happiestSeasonTrailerPTBR.mp4";
import { Carousel } from "primereact/carousel";
import exemploimg from "../../../assests/imgs/Gloria-Pires.png"
import floresRaras from "../../../assests/imgs/Gloria-Pires.png"
import imagineYou from "../../../assests/imgs/imagine-you-and-me.png"
import bottoms from "../../../assests/imgs/Bottoms.png"
import loveLies from "../../../assests/imgs/Love-Liers-Bleeding.jpg"
import iconfilm from "../../../assests/imgs/iconfilm.png"
import iconclass from "../../../assests/imgs/incon-class.png"
import Therm from "../../../Components/Thermometer";



const Info = () => {
    const { userInfos } = useContext(GlobalStateContext);

    const navigate = useNavigate();

    

    const [isVideoEnded, setIsVideoEnded] = useState(false);

    // Função para tratar o evento de término do vídeo
    const handleVideoEnd = () => {
        setIsVideoEnded(true);
    };

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const mockProducts = [
        {
          id: 1,
          name: "Flores Raras",
          image: floresRaras
        },
        {
          id: 2,
          name: "Imagine Me and You",
          image: imagineYou
        },
        {
          id: 3,
          name: "Bottoms",
          image: bottoms
        },
        {
            id: 1,
            name: "Love Lies Bleeding",
            image: loveLies
          },
          {
            id: 2,
            name: "Série E",
  
            image: exemploimg
          },
          {
            id: 3,
            name: "Série F",
            image: exemploimg
          },
  
      ];

      const [products, setProducts] = useState(mockProducts);

      const productTemplate = (product) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                <div className="mb-3">
                    <img style={{ width: "96%", height:"214px", borderRadius: "20px"}} src={`${product.image}`} alt={product.name} className="w-6 shadow-2" />
                </div>
                {/* <div>
                    <h4 style={{fontWeight: 400}} className="mb-1">{product.name}</h4>
                </div> */}
            </div>
        );
    };
      
    useEffect(() => {
        // Este código irá verificar se o vídeo terminou e irá trocar para a imagem estática.
        const video = document.getElementById('background-video');
        if (video) {
            video.addEventListener('ended', handleVideoEnd);
        }

        // Cleanup function para remover o event listener
        return () => {
            if (video) {
                video.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, []);

    return (
        <>
            <BodyImg >
                <MenuHome />
                    <img style={{ height: '100%', width: '100%' }} src={fundoinfo} alt="Fundo Estático" />

                <div style={{ position: "absolute", bottom: "0", marginBottom: "60px", marginLeft: "50px"}}>
                    <MidPosition>
                        <MidImg src={texthomepage} />
                    </MidPosition>
                        
                    <ButtonDisplay>
                        <ButtonGradient text={"Assistir"} onClick={() => navigate('/movieScreen')} />
                        <img src={iconfilm}/>
                    </ButtonDisplay>
                    <div style={{ display: "flex", gap: "30px", marginBottom: "40px", marginTop: "20px"}}>
                        <TextBody>1h42 min</TextBody>
                        <img src={iconclass}/>
                        <TextBody>2020</TextBody>
                        <TextBody>Drama</TextBody>
                        <Therm/>
                    </div>
                    <TextBody style={{fontWeight: 300, marginTop: 60, fontSize: 20}}>As namoradas Harper e Abby visitam a família de Harper para o jantar anual de Natal. No entanto, logo após chegar, Abby percebe que a moça tem mantido seu relacionamento em segredo de seus pais conservadores.</TextBody>
                </div>

            </BodyImg>
            <Body>
                <DivideBody style={{ gap: "40px" }}>
                    <BoxNoHover style={{ width: "45%", flexDirection: "column", justifyContent: 'center', marginTop: '3%' }}>
                        
                    </BoxNoHover>
                    {/* <Box style={{ width: "45%", height: 230, backgroundColor: '#D9D9D9', marginRight: "3%", borderRadius: 30 }}>
                        <img src={fisrImg} style={{ height: '100%', width: '100%', borderRadius: 30 }} />
                    </Box> */}

                </DivideBody>
                <DivideBody style={{ marginTop: 50 }}>
                    {/* <Box style={{ width: 900, height: 230, backgroundColor: '#D9D9D9', marginLeft: "3%", marginRight: "3%", borderRadius: 30 }}>
                        <img src={secImg} style={{ height: '100%', width: '100%', borderRadius: 30 }} />
                    </Box>
                    <Box style={{ width: 900, height: 230, backgroundColor: '#D9D9D9', marginRight: "3%", borderRadius: 30 }}>
                        <img src={terstImg} style={{ height: '100%', width: '100%', borderRadius: 30 }} />
                    </Box> */}
        
                    <div className="card">
                    <TextBody style={{fontSize: 25, color: 'white', marginLeft: '50px'}}>Títulos Relacionados</TextBody>
                        <Carousel value={products} numVisible={4} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular data-pr-autohide
                            itemTemplate={productTemplate} />
                    </div>
                </DivideBody>
            </Body>
        </>
    )
}

export default Info;