import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import React, { useRef, useState } from "react";

import class_10 from "../../../assests/AgeClassification/class_10.svg.webp";
import class_12 from "../../../assests/AgeClassification/class_12.svg.webp";
import class_14 from "../../../assests/AgeClassification/class_14.svg.webp";
import class_16 from "../../../assests/AgeClassification/class_16.svg.webp";
import class_18 from "../../../assests/AgeClassification/class_18.svg.webp";
import class_L from "../../../assests/AgeClassification/class_L.svg.webp";
import { Body, Button, ClassificationImage, FilmDetails, FilmItem, FormContainer, Input, RatingContainer, RemoveButton, StyledFileUpload, StyledTabPanel, StyledTabView, Textarea } from "./styled";

const UpFilm = () => {
  const [films, setFilms] = useState([]);
  const [filmData, setFilmData] = useState({
    titlept: "",
    title: "",
    sinopse: "",
    classfication: "",
    duration: "",
    classification: "",
    duration: "",
    year: "",
    direction: "",
    roadmap: "",
    cast: "",
    gender: "",
    category: "",
    file: null,
  });

  const classificationImages = {
    L: class_L,
    10: class_10,
    12: class_12,
    14: class_14,
    16: class_16,
    18: class_18,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilmData({ ...filmData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilms([...films, filmData]);
    setFilmData({
      titlept: "",
      title: "",
      sinopse: "",
      classfication: "",
      duration: "",
      classification: "",
      duration: "",
      year: "",
      direction: "",
      roadmap: "",
      cast: "",
      gender: "",
      category: "",
      cpb: "",

      file: null,
      video: null,
      audio: null,
      legend: null,
      trailer: null,
      banner: null,
      imagebank: null,

      contracts: null,
      licensing: null,
      expiration: null,
      producer: null,
      merchandising: null,
    });
  };

  const handleRemove = (index) => {
    setFilms(films.filter((_, i) => i !== index));
  };

  const [classification, setClassification] = useState("");

  
  const [files, setFiles] = useState({
    video: { name: "" },
    audio: { name: "" },
    legend: { name: "" },
    trailer: { name: "" },
    banner: { name: "" },
    imagebank: { name: "" },
    contracts: { name: "" },
    licensing: { name: "" },
    expiration: { name: "" },
    producer: { name: "" },
    merchandising: { name: "" },
  });

  const fileInputRefs = {
    video: useRef(null),
    audio: useRef(null),
    legend: useRef(null),
    trailer: useRef(null),
    banner: useRef(null),
    imagebank: useRef(null),
    contracts: useRef(null),
    licensing: useRef(null),
    expiration: useRef(null),
    producer: useRef(null),
    merchandising: useRef(null),
  };

  
  const handleFileChange = (event, type) => {
    const file = event.files && event.files[0];

    if (file) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [type]: { name: file.name },
      }));
    }
  };

  const handleResetFile = (name) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: { name: "" },
    }));
    if (fileInputRefs[name].current) {
      fileInputRefs[name].current.clear(); 
    }
  };

  
  const renderFileUploadSection = (id, name, label, accept) => (
    <div>
      <label htmlFor={`${id}-upload`}>{label}:</label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <StyledFileUpload
          id={`${id}-upload`}
          name={name}
          ref={fileInputRefs[name]} // Atribuindo a referência ao componente
          customUpload
          uploadHandler={(event) => handleFileChange(event, name)}
          auto
          chooseLabel={`Escolher ${label}`}
          accept={accept}
          mode="basic"
        />
        <span style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
          {files[name].name ? (
            <>
              <strong>{files[name].name}</strong>
              <RemoveButton
                onClick={() => handleResetFile(name)}
              >
                X
              </RemoveButton>
            </>
          ) : (
            <em>Nenhum arquivo selecionado</em>
          )}
        </span>
      </div>
    </div>
  );


  const handleClassificationClick = (value) => {
    setClassification(value);
  };



  return (
    <Body>
      <FormContainer onSubmit={handleSubmit}>
        <h2>Ficha Técnica</h2>
        <div className="form-row">
          <Input type="text" name="titlept" placeholder="Título PT" />
          <Input type="text" name="title" placeholder="Título Original" />
        </div>
        <Textarea name="sinopse" placeholder="Sinopse" rows="5" />
        <div className="form-row">
          <RatingContainer>
            {Object.keys(classificationImages).map((key) => (
              <ClassificationImage
                key={key}
                src={classificationImages[key]}
                alt={key}
                selected={classification === key}
                onClick={() => handleClassificationClick(key)}
              />
            ))}
          </RatingContainer>
          <Input type="number" name="duration" placeholder="Duração" />
        </div>
        <div className="form-row">
          <Input type="number" name="year" placeholder="Ano" />
          <Input type="text" name="gender" placeholder="Gênero" />
        </div>
        <div className="form-row">
          <Input type="text" name="direction" placeholder="Direção" />
          <Input type="text" name="roadmap" placeholder="Roteiro" />
        </div>
        <div className="form-row">
          <Textarea name="cast" placeholder="Elenco" rows="3" />
        </div>
        <div className="form-row">
          <Input type="text" name="category" placeholder="Categoria" />
          <Input type="text" name="cpb" placeholder="Registro Ancine (CPB)" />
        </div>
        <div className="form-files">
          <h2>Upload de Conteúdo</h2>
          {renderFileUploadSection("video", "video", "Vídeo", "video/*")}
          {renderFileUploadSection("audio", "audio", "Áudio", "audio/*")}
          {renderFileUploadSection("legend", "legend", "Legendas")}
          {renderFileUploadSection("trailer", "trailer", "Trailer")}
          {renderFileUploadSection("banner", "banner", "Banner")}
          {renderFileUploadSection(
            "imagebank",
            "imagebank",
            "Banco de Imagens"
          )}

          <h2>Documentação</h2>

          {renderFileUploadSection("contracts", "contracts", "Contratos")}
          {renderFileUploadSection("licensing", "licensing", "Licenciamento")}
          {renderFileUploadSection(
            "expiration",
            "expiration",
            "Data de Expiração"
          )}
          {renderFileUploadSection("producer", "producer", "Produtora")}
          {renderFileUploadSection(
            "merchandising",
            "merchandising",
            "Liberação para Merchandising"
          )}
        </div>
        <div className="form-button">
        <Button type="submit">Enviar</Button>
        </div>
      </FormContainer>

      <h2 style={{ color: "white" }}>Enviados</h2>
      <div>
        {films.map((film, index) => (
          <FilmItem key={index}>
            <FilmDetails>
              <p>Titulo PT: {film.titlept}</p>
              <p>Titulo: {film.title}</p>
              <p>Sinopse: {film.sinopse}</p>
              <p>Rating: {film.rating}</p>
              <p>Duration: {film.duration}</p>
              <p>Classification: {film.classification}</p>
              <p>Duração: {film.duration}</p>
              <p>Ano: {film.year}</p>
              <p>Direção: {film.direction}</p>
              <p>Roteiro: {film.roadmap}</p>
              <p>Elenco: {film.cast}</p>
              <p>Gênero: {film.gender}</p>
              <p>Categoria: {film.category}</p>
              <p>Registro Ancine: {film.cpb}</p>
            </FilmDetails>
            <RemoveButton onClick={() => handleRemove(index)}>
              Remove
            </RemoveButton>
          </FilmItem>
        ))}
      </div>
    </Body>
  );
};

export default UpFilm;
