import styled from "styled-components";

export const ButtonB = styled.button`
    width: 200px;
    height: 43px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    cursor: pointer;

    color: #000;
    text-align: center;
    font-family: Modern Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 10px;
    border: 2px solid #D9D9D9;
    background: #FFF;

    transition: opacity 0.3s ease; /* Adiciona uma transição suave para a opacidade */

    :hover {
        opacity: 0.5; /* Define a opacidade desejada no hover */
    }
`;