import React, { useContext } from "react";

import iconplay from "../../../assests/imgs/iconeplay.png";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { ButtonG } from "./styled";

const ButtonGradientEdit = ({
  onClick,
  text,
  width,
  height,
  fontSize,
  fontFamily,
}) => {
  const { userInfos } = useContext(GlobalStateContext);

  return (
    <ButtonG
      style={{
        width: width,
        height: height,
        fontSize: fontSize,
        fontFamily: fontFamily,
      }}
      onClick={() => onClick()}
    >
      {text}
    </ButtonG>
  );
};

export default ButtonGradientEdit;
