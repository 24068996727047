import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

@font-face {
    font-family: 'MyCustomFont';
    src: url('https://fonts.cdnfonts.com/css/typoslab-irregular-demo-2') format('woff'); // Use o formato correto do arquivo
  }
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');


  body {
    margin: 0;
    padding: 0;
    color: ${props => (props.darkMode ? 'white' : 'black')};
  }
`