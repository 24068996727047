import { Menu } from "primereact/menu";
import styled from "styled-components";

export const StyledMenu = styled(Menu)`
  background-color: #202020;
  width: 230px;
  opacity: 0.85;
  border-radius: 5px;
  margin-top: 10px;
  li {
    background-color: #202020;
    color: white;
  }

  a {
    background-color: #202020;
    color: white;

    :hover {
      background-color: #171717;
      transition: 0.5s ease;
    }
  }

  span {
    color: white;
  }
`;

export default StyledMenu;
