import styled from 'styled-components';

export const Body = styled.div`
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    width: 100%;
    min-height: 100vh;
    color: #fff;

 `

export const ButtonImg = styled.div`
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
cursor: pointer;

:hover {
    opacity: 0.5; /* Define a opacidade desejada */
}
`;