import styled from "styled-components";
import { FaPencilAlt } from 'react-icons/fa'; // Usando react-icons para o ícone

export const PlanCardContainer = styled.div`

  background-color: #961F0133;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
`;

export const PlanName = styled.span`
  font-weight: bold;
`;

export const PlanPrice = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

export const EditIcon = styled(FaPencilAlt)`
  cursor: pointer;
`;