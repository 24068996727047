import React from 'react';
import { EditIcon, PlanCardContainer, PlanName, PlanPrice } from './styled';
import { useNavigate } from 'react-router-dom';


const PlanCard = ({ name, price, onEdit }) => {

  const navigate = useNavigate();


  return (
    <PlanCardContainer>
      <PlanName>{name}</PlanName>
      <div>
        <PlanPrice>R$ {price}</PlanPrice>
        <EditIcon onClick={() => navigate('/plans')} />
      </div>
    </PlanCardContainer>
  );
};

export default PlanCard;
