import styled from "styled-components";


export const BodyImg = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    z-index: 1;

`;





export const TittlePage = styled.text`
font-style: normal;
font-size: 150px;
font-weight: 400;
line-height: 70px;
letter-spacing: 0em;
color: white;
margin-top: 150px;
margin-bottom: 100px;

`

export const SubTittle = styled.text`
width: 386px;

font-style: normal;
font-size: 17px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
color: white;
margin-top: 100px;
margin-bottom: 100px;
text-align: center;

`

export const StyledCarousel = styled.div`

  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
    background: #CC005B;
    color: #ffffff;
  }
`;