import styled from 'styled-components';

export const Body = styled.div`
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    width: 100%;
    min-height: 100vh;
    color: #fff;

    .card {
        background-color: #9b3c3c; /* Background do card */
        padding: 20px;
        border-radius: 10px;
    }

    .steps {
        background-color: #8b2c2c; /* Background dos números */
        border-radius: 10px;

        .p-steps-item {
            &.p-steps-current {
                background-color: #4a4a4a; /* Novo background para a etapa ativa */

                .p-steps-number {
                    background-color: red; /* Cor de fundo do número ativo */
                    color: #fff; /* Cor do texto do número ativo */
                }
            }
        }
    }

    .p-button {
        background-color: #6a1b1b; /* Cor do botão */
        border-color: #6a1b1b;
        color: #fff;

        &:hover {
            background-color: #501515; /* Cor do botão no hover */
            border-color: #501515;
        }
    }

    .p-dialog .p-dialog-footer .p-button {
        background-color: red !important; /* Cor dos botões do pop-up */
        border-color: red !important;
        color: #fff !important;

        &:hover {
            background-color: #501515 !important; /* Cor dos botões do pop-up no hover */
            border-color: #501515 !important;
        }
    }
`;