import React, { useState } from "react";
import { Body, ButtonRegister, CardLogin, InputRegister, TittleCard, LogoB, CheckBoxText } from "./styled";
import { SkewLoader } from "react-spinners";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import logo from "../../../assests/imgs/simbolosemnomebranco.png";
import { Checkbox } from "primereact/checkbox";
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import styled from "styled-components";
import { Password } from 'primereact/password';

const CustomCheckbox = styled(Checkbox)`
    .p-checkbox-box {
        background: linear-gradient(to right, #A40464, #f45b69);
        border: none;
    }

    .p-checkbox-box.p-highlight {
        background: linear-gradient(to right, #A40464, #f45b69);
    }

    .p-checkbox-box .p-checkbox-icon {
        color: white;
    }
`;

const Register = () => {
    const [register, setRegister] = useState({
        fullName: null,
        email: null,
        password: "",
    });
    const [checkPassword, setCheckPassword] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [check, setCheck] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [checkPasswordVisible, setCheckPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState("");
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validatePhone = (phone) => {
        const re = /^\(\d{2}\) \d{4,5}-\d{4}$/;
        return re.test(phone);
    }

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setEmailValid(validateEmail(email)); // Atualiza o estado de validade do e-mail
        setRegister(prevState => ({ ...prevState, email }));
    }

    const handleRegister = () => {
        if (register.fullName === "" || register.email === "" || register.password === "" || register.phone === "" || checkPassword === "" || !check) {
            setFormError("Todos os campos são obrigatórios e a Política de Privacidade e Termos e Condições devem ser aceitos.");
            return;
        }

        if (checkPassword !== register.password) {
            setFormError("As senhas não conferem.");
            return;
        }

        setLoading(true);
        api.post("/register", register)
            .then((res) => {
                console.log(res);
                setLoading(false);
                navigate('/registerpag');
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    const handlePhoneChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        value = value.slice(0, 11);

        value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
        if (value.length > 10) {
            value = value.replace(/(\d)(\d{4})$/, "$1-$2");
        } else {
            value = value.replace(/(\d)(\d{4})$/, "$1-$2");
        }

        setPhoneValid(validatePhone(value));
        setRegister(prevState => ({ ...prevState, phone: value }));
    };

    const handleKeyPress = (e, nextFieldId) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextField = document.getElementById(nextFieldId);
            if (nextField) nextField.focus();
        }
    };

    return (
        <Body>
            {loading ?
                <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", zIndex: 10 }}>
                    <SkewLoader
                        color="#A40464"
                        loading={true}
                        size={50}
                    />
                </div>
                : null
            }

            <div style={{ textAlign: "center" }}>
                <LogoB src={logo} />
                <p style={{ color: "#FFFFFF" }}>PASSO 2 DE 3</p>
            </div>

            <CardLogin>
                <TittleCard>Quero me cadastrar</TittleCard>
                <InputRegister id="nameInput" value={register.name} onChange={(e) => setRegister(prevState => ({ ...prevState, name: e.target.value }))} onKeyPress={(e) => handleKeyPress(e, 'emailInput')} placeholder="Nome" />
                <InputRegister id="emailInput" value={register.email} onChange={handleEmailChange} onKeyPress={(e) => handleKeyPress(e, 'telInput')} placeholder="E-mail" />
                {!emailValid && <text style={{ color: "black", marginTop: 10 }}>E-mail inválido</text>}

                <InputRegister id="telInput" value={register.phone} onChange={handlePhoneChange} onKeyPress={(e) => handleKeyPress(e, 'passwordInput')} placeholder="Telefone" />
                {!phoneValid && <text style={{ color: "black", marginTop: 10 }}>Telefone inválido</text>}

                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Password
                        id="passwordInput"
                        toggleMask
                        feedback
                        value={register.password}
                        onChange={(e) => setRegister(prevState => ({ ...prevState, password: e.target.value }))}
                        onKeyPress={(e) => handleKeyPress(e, 'passwordCheckInput')}
                        placeholder="Senha"
                        inputStyle={{
                            height: '50px',
                            width: '440px',
                            background: '#D9D9D9',
                            border: 'none',
                            borderRadius: '5px',
                            paddingLeft: '10px',
                            fontWeight: '100',
                            fontSize: '20px',
                            lineHeight: '29px',
                            letterSpacing: '0.2em',
                            marginTop: '20px'
                        }}
                    />
                </div>

                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ position: 'relative', width: '440px' }}>
                        <InputRegister
                            id="passwordCheckInput"
                            type={checkPasswordVisible ? "text" : "password"}
                            value={checkPassword}
                            onChange={(e) => setCheckPassword(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e, 'buttonSubmit')}
                            placeholder="Confirme a senha"
                        />
                        <span style={{ cursor: 'pointer', position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} onClick={() => setCheckPasswordVisible(!checkPasswordVisible)}>
                            {checkPasswordVisible ? <EyeOffIcon style={{ width: 20, height: 20 }} /> : <EyeIcon style={{ width: 20, height: 20 }} />}
                        </span>
                    </div>
                </div>
                {checkPassword === register.password ? null : <text style={{ color: "black", marginTop: 10 }}>As senhas não conferem</text>}

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 20, width: "100%", marginLeft: 70 }}>
                    <div className="card flex justify-content-center">
                        <CustomCheckbox onChange={e => setCheck(e.checked)} checked={check} />
                    </div>
                    <CheckBoxText style={{ marginLeft: 10 }}>Política de Privacidade e Termos e Condições</CheckBoxText>
                </div>
                {formError && <text style={{ color: "black", margin: 15 }}>{formError}</text>}

                <ButtonRegister id="buttonSubmit" onClick={handleRegister}>Me cadastrar</ButtonRegister>

            </CardLogin>
        </Body>
    )
}

export default Register;
