import styled from "styled-components";

export const ButtonG = styled.button`
    width: 200px;
    height: 43px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    cursor: pointer;

    color: #FFF;
    text-align: center;
    font-family: Modern Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 10px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.88%, #FC9B54 100%);

    transition: opacity 0.3s ease; /* Adiciona uma transição suave para a opacidade */

    :hover {
        opacity: 0.5; /* Define a opacidade desejada */
    }
`;