import logo from './logo.svg';
import './App.css';
import Routers from './Router';
import GlobalState from './GlobalState/GlobalState';
// import "primereact/resources/themes/mira/theme.css";
import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
import GlobalCSS from './GlobalStyled/index.css'
import 'primeicons/primeicons.css';

function App() {
  return (
    <GlobalState>
      <GlobalCSS/>
      <Routers/>
      </GlobalState>
  );
}

export default App;
